import React from 'react';
import { Link, NavLink } from "react-router-dom";

// Css Files
import '../../css/footer.css';

// Images Common Files
import * as Img from '../Img';
import flag1 from "../../img/flag/flag(1).jpg";
import flag4 from "../../img/flag/flag(4).jpg";

// Design Files
// import Socialicon from '../Socialicon';

export default function Footer() {

    const footercontactinfo = [
        // {
        //     contactClassName: "col-md-12 col-sm-12 whatsapp-link",
        //     contactName: "whatsapp",
        //     contactImg: Img.whatsapp,
        //     contactData: "whatsapp",
        //     contactLink: "https://api.whatsapp.com/send?phone=+918081113111&text=Hello",
        // },
        {
            contactClassName: "col-md-12 col-sm-6",
            contactName: "Call Us",
            contactImg: Img.call,
            contactData: "+971 554406524",
            contactLink: "tel:+971554406524",
        },
        {
            contactClassName: "col-md-12 col-sm-12",
            contactName: "Email Us",
            contactImg: Img.sms,
            contactData: "support@airrsanfx.com",
            contactLink: "mailto: support@airrsanfx.com",
        },
        // {
        //     contactClassName: "col-12",
        //     contactName: "Address",
        //     contactImg: Img.location,
        //     // contactData: "1st Floor  The Sotheby Building Rodney Bay Gros-Islet,SAINT Lucia P.O Box 838 Castries Saint Lucia.",
        //     contactData: "P.O Box 838 Castries, Saint Lucia.",
        //     contactLink: "/",
        // },
    ]

    const AddressInfo = [
        {
            contactClassName: "col-12 mb-2",
            contactName: "Registered Address:",
            contactImg: flag4,
            contactData: "Ground Floor, The Sotheby Building, Rodney Bay, Gros-Islet, Saint Lucia P.O. Box 838, Castries, Saint Lucia",
            contactLink: "",
        },
        // {
        //     contactClassName: "col-12 mb-2",
        //     contactName: "Physical Address:",
        //     contactImg: flag1,
        //     contactData: "402, Meredian Business Centre, Mota Varachha, Surat, Gujarat, India, 394101.",
        //     contactLink: "",
        // },
    ]

    return (
    <>        
        <footer className="main-footer pt-5 pb-1">
            <div className="container-lg cl-custome">
                <div className="row justify-content-lg-between justify-content-sm-around">

                    <div data-aos="fade-up" className="col-lg-3 col-12 mb-lg-0 mb-4">

                        <div className="text-lg-start text-center">
                            <Link className="footer-logo" to="/">
                                <img src={Img.logo} alt=" " />
                            </Link>
                            <div className="footer-sub-heading">Engage in trading with AirrsanFx – a single platform offering multiple opportunities.</div>
                        </div>
                        
                    </div>

                    <div data-aos="fade-up" className="col-xl-2 col-lg-3 col-md-4 col-12 mb-md-0 mb-4">
                        <div className="footer-heading">Explore</div>
                        <div className="footer-links-bx">
                            <NavLink to="/" className="footer-link">Home</NavLink>
                            <NavLink to="/about" className="footer-link">About Us</NavLink>
                            <NavLink to="/faqs" className="footer-link">FAQs</NavLink>
                            {/* <NavLink to="/privacy-policy" className="footer-link">Privacy Policy</NavLink>
                            <NavLink to="/terms-and-conditions" className="footer-link">Terms & Conditions</NavLink> */}
                            <NavLink to="/contact" className="footer-link">Contact Us</NavLink>
                        </div>
                    </div>

                    <div data-aos="fade-up" className="col-lg-3 col-md-4 col-sm-6 mb-sm-0 mb-4">

                        <div className="footer-heading">Contact Info</div>
                        <div className="row">
                            { footercontactinfo.map((item, index) => ( 
                                <div className={item.contactClassName} key={index}>
                                    <Link className="footer-contact-info fci-nowrap" to={item.contactLink}>
                                        
                                        <img src={item.contactImg} alt={`${item.contactName} Icon`} />
                                        
                                        {item.contactData}
                                        
                                    </Link>
                                </div>
                            ))}
                        </div>

                        {/* <Socialicon className="social-icon justify-content-lg-start mt-3" /> */}
                    </div>

                    <div data-aos="fade-up" className="col-lg-3 col-md-4 col-sm-6">
                        <div className="footer-heading">Address</div>
                        <div className="row">
                            { AddressInfo.map((item, index) => ( 
                                <div className={item.contactClassName} key={index}>
                                    <div className="footer-contact-lable">

                                        {( item.contactImg && <img src={item.contactImg} alt=" " /> )}
                                        {item.contactName}
                                    </div>
                                    <Link className="footer-contact-info" to={item.contactLink}>
                                        {item.contactData}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="col-12 pt-md-4"></div>

                    <div data-aos="fade-up" className="col-md-6 mt-4">
                        <div className="footer-heading">Risk Warning</div>
                        <div className="footer-sub-heading fsh-t">Please note that forex trading and trading in other leveraged products involves a significant level of risk and is not suitable for all investors. Trading in financial instruments may result in losses as well as profits and your losses can be greater than your initial invested capital. Before undertaking any such transactions, you should ensure that you fully understand the risks involved and seek independent advice if necessary.</div>
                    </div>

                    <div data-aos="fade-up" className="col-md-6 mt-4">
                        <div className="footer-heading">Restricted Regions</div>
                        <div className="footer-sub-heading fsh-t">AirrsanFx Limited does not provide services for citizens/residents of the USA, Cuba, Iraq, Myanmar, North Korea, Sudan. The services of AirrsanFx Limited are not intended for distribution to, or use by, any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation.</div>
                    </div>
                </div>

                <div className="footer-bottom-link">
                    <div className="footer-links-bx">
                        <NavLink to="/privacy-policy" className="footer-link">Privacy Policy</NavLink>
                        <NavLink to="/terms-and-conditions" className="footer-link">Terms & Conditions</NavLink>
                        <NavLink to="/aml-policy" className="footer-link">AML Policy</NavLink>
                        <NavLink to="/deposit-withdrawal-policy" className="footer-link">Deposit & withdrawal Policy</NavLink>
                        <NavLink to="/restricted-countries" className="footer-link">Restricted Countries</NavLink>
                        <NavLink to="/risk-disclosure" className="footer-link">Risk Disclosure</NavLink>
                    </div>
                </div>

                <div className="footer-bottom justify-content-center">
                    <div className="copyright">© 2024 All Rights Reserved By AirrsanFx</div>
                    {/* <div className="mede-with">Made with
                        <span className="icons-box-made">
                            <i className="fa fa-heart-o" aria-hidden="true"></i>
                            <i className="fa fa-heart" aria-hidden="true"></i>
                        </span>
                        by: <a href="https://pmcommu.com/" target="_blank" rel="noreferrer">PM Communications</a>
                    </div> */}
                </div>

            </div>
        </footer> 

    </>
    );
}